import React from "react"
import Layout from "../../components/layout/Layout"
/* import Grid from "../../components/grid/Grid"
import Card from "../../components/card/Card"
import Date from "../../components/date/Date"*/
import Markdown from "../../components/markdown/Markdown"
import css from "./Day.module.css"
import Section from "../../components/section/Section"

export default ({ pageContext: { url, node, data } }) => {
  console.log(node)
  return (
    <Layout
      type="HMF"
      className={css.day}
      slug={`${url}/${node.slug}`}
      title={node.titulo}
    >
      <Section className={css.header}>
        <div className={css.headerContainer}>
          <div className={css.top}>
            <div className={css.day}>{node.dia}</div>
            <div className={css.month}>{node.mes}</div>
          </div>
          <h1>{node.nombre}</h1>
          <Markdown className={css.description}>
            {node.presentacion.childMarkdownRemark.html}
          </Markdown>
        </div>
      </Section>
      <Section className={css.events}>
        <div className={css.eventsContainer}>
          {node.eventosAnuales && (
            <>
              <div className={css.gridDay}>
                {node.eventosAnuales.map((evento, i) => (
                  <div key={i} className={css.event}>
                    <img src={evento.afiche.file.url} alt="poster" />
                    <h3>
                      {evento.anho} : {evento.titulo}
                    </h3>
                    <Markdown>
                      {evento.cuerpo.childMarkdownRemark.html}
                    </Markdown>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Section>
    </Layout>
  )
}
